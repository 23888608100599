import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import DeleteIcon from '@material-ui/icons/Delete';

import Utils from '../Utils';

//  --------------------------------------------------------------------------------------------------

export default function Basket(props) {

   const { config, basket, edit, onEdit, onDelete } = props;

   const classes = useStyles(config.theme);

   return (
      <>
         {
            basket.owner && (
               <Paper key="owner" className={classes.paper} elevation={1}>
                  <table className={classes.orderTable}>
                     <tbody>
                        <tr>
                           <td className={classes.orderLabel}>Owner:</td>
                           <td className={classes.orderValue}>{basket.owner.name}</td>
                        </tr>
                        <tr>
                           <td className={classes.orderLabel}>Submitted:</td>
                           <td className={classes.orderValue}>{Utils.formatTimestamp(basket.owner.submitted)}</td>
                        </tr>
                     </tbody>
                  </table>
               </Paper>
            )
         }
         {
            basket.orders.map((order, orderIdx) => (
               <Paper key={'order' + orderIdx} className={classes.paper} elevation={1}>
                  <table className={classes.orderTable}>
                     <tbody>
                        <tr>
                           <td className={classes.orderLabel}>Vendor:</td>
                           <td className={classes.orderValue}>{order.vendor}</td>
                        </tr>
                        <tr>
                           <td className={classes.orderLabel}>For:</td>
                           <td className={classes.orderValue}>{order.recipient}</td>
                        </tr>
                        <tr>
                           <td className={classes.orderLabel}>Address:</td>
                           <td className={classes.orderValue}>{order.address}</td>
                        </tr>
                        <tr>
                           <td className={classes.orderLabel}>Delivery:</td>
                           <td className={classes.orderValue}>{order.how}</td>
                        </tr>
                        {
                           order.instruction && (
                              <tr>
                                 <td className={classes.orderLabel}>Instruction:</td>
                                 <td className={classes.orderValue}>{order.instruction}</td>
                              </tr>
                           )
                        }
                     </tbody>
                  </table>
                  <table className={classes.detailTable}>
                     <thead>
                        <tr>
                           <td className={classes.detailNameHeading}>Name</td>
                           <td className={classes.detailOtherHeading}>Quantity</td>
                           <td className={classes.detailOtherHeading}>Price (£)</td>
                           <td className={classes.detailOtherHeading}>VAT (£)</td>
                           { edit && <td className={classes.detailDeleteHeading}/> }
                        </tr>
                     </thead>
                     <tbody>
                        {
                           order.items.map((item, itemIdx) => (
                              <>
                                 <tr className={classes.detailRow} key={'item' + itemIdx}>
                                    <td className={item.id ? classes.detailItemNameFirst : classes.detailItemName}>
                                       {
                                          item.id ? edit ? (
                                             <Link color="primary" className={classes.detailValue} href="#" onClick={() => onEdit(item.id)}>{item.name}</Link>
                                          ) : (
                                             <span className={classes.detailValue}>{item.name}</span>
                                          ) : (
                                             item.name
                                          )
                                       }
                                       {
                                          (item.summary.length > 0) && (
                                             <>
                                                <br/>
                                                {
                                                   item.summary.map((summary, summaryIdx) => (
                                                      <>
                                                         {
                                                            (summaryIdx > 0) ? <span key={'name' + summaryIdx} className={classes.detailLabel}>; {summary.name}: </span> :
                                                                               <span key={'name' + summaryIdx} className={classes.detailLabel}>{summary.name}: </span>
                                                         }
                                                         <span key={'value' + summaryIdx} className={classes.detailValue}>"{summary.value}"</span>
                                                      </>
                                                   ))
                                                }
                                             </>
                                          )
                                       }
                                       {
                                          item.artwork1 && (
                                             <>
                                                <br/>
                                                <span className={classes.detailLabel}>Artwork #1: </span>
                                                <span className={classes.detailValue}>{item.artwork1}</span>
                                             </>
                                          )
                                       }
                                       {
                                          item.artwork2 && (
                                             <>
                                                <br/>
                                                <span className={classes.detailLabel}>Artwork #2: </span>
                                                <span className={classes.detailValue}>{item.artwork2}</span>
                                             </>
                                          )
                                       }
                                    </td>
                                    <td className={item.id ? classes.detailItemValueFirst : classes.detailItemValue}>{item.quantity}</td>
                                    <td className={item.id ? classes.detailItemValueFirst : classes.detailItemValue}>{item.inclusive ? '\u2014' : item.price}</td>
                                    <td className={item.id ? classes.detailItemValueFirst : classes.detailItemValue}>{item.inclusive ? '\u2014' : item.vat}</td>
                                    {
                                       edit && (
                                          <td className={classes.detailItemDelete}>{
                                             item.id && <IconButton size="small" onClick={() => onDelete(item.id)}><DeleteIcon/></IconButton>
                                          }</td>
                                       )
                                    }
                                 </tr>
                                 {
                                    (item.errors.length > 0) && (
                                       <tr className={classes.detailRow} key={'errors' + itemIdx}>
                                          <td colSpan={edit ? 5 : 4}>{
                                             item.errors.map((error, errorIdx) => <Typography key={'error' + errorIdx} color="secondary">{error}</Typography>)
                                          }</td>
                                       </tr>
                                    )
                                 }
                              </>
                           ))
                        }
                        <tr key="totals">
                           <td className={classes.detailTotalBlank}/>
                           <td className={classes.detailTotalBlank}/>
                           <td className={classes.detailTotal}>{order.price}</td>
                           <td className={classes.detailTotal}>{order.vat}</td>
                           { edit && <td/> }
                        </tr>
                     </tbody>
                  </table>
               </Paper>
            ))
         }
         <Paper className={classes.paper} elevation={1}>
            <table className={classes.totalsTable}>
               <tbody>
                  <tr>
                     <td className={classes.totalsLabel}>Price</td>
                     <td className={classes.totalsPound}>£</td>
                     <td className={classes.totalsValue}>{basket.price}</td>
                     { edit && <td className={classes.totalsDelete}/> }
                  </tr>
                  <tr>
                     <td className={classes.totalsLabel}>VAT</td>
                     <td className={classes.totalsPound}>£</td>
                     <td className={classes.totalsValue}>{basket.vat}</td>
                     { edit && <td className={classes.totalsDelete}/> }
                  </tr>
                  <tr>
                     <td className={classes.totalsLabel}>Total</td>
                     <td className={classes.totalsPound}>£</td>
                     <td className={classes.totalsFinal}>{basket.total}</td>
                     { edit && <td className={classes.totalsDelete}/> }
                  </tr>
               </tbody>
            </table>
         </Paper>
      </>
   );

}

//  --------------------------------------------------------------------------------------------------

const useStyles = makeStyles({
   paper                     : (theme) => ({
      ...theme.mixins.gutters(),
      marginTop              : theme.spacing(2),
      marginBottom           : theme.spacing(2),
      paddingTop             : theme.spacing(2),
      paddingBottom          : theme.spacing(2)
   }),
   orderTable                : {
      width                  : '100%',
      borderSpacing          : '4px'
   },
   orderLabel                : {
      width                  : '74px'
   },
   orderValue                : {
      fontWeight             : 'bold'
   },
   detailTable               : {
      marginTop              : '8px',
      width                  : '100%',
      borderSpacing          : '4px'
   },
   detailRow                 : {
      verticalAlign          : 'top'
   },
   detailNameHeading         : {
      textAlign              : 'left'
   },
   detailOtherHeading        : {
      width                  : '80px',
      textAlign              : 'right'
   },
   detailDeleteHeading       : {
      width                  : '30px'
   },
   detailItemNameFirst       : {
      borderTop              : '1px solid black',
      paddingTop             : '4px',
      textAlign              : 'left'
   },
   detailItemValueFirst      : {
      borderTop              : '1px solid black',
      paddingTop             : '4px',
      textAlign              : 'right',
      fontWeight             : 'bold'
   },
   detailItemName            : {
      textAlign              : 'left'
   },
   detailItemValue           : {
      textAlign              : 'right',
      fontWeight             : 'bold'
   },
   detailItemDelete          : {
      textAlign              : 'center'
   },
   detailLabel               : { },
   detailValue               : {
      fontWeight             : 'bold'
   },
   detailTotalBlank          : {
      borderTop              : '1px solid black'
   },
   detailTotal               : {
      borderTop              : '1px solid black',
      borderBottom           : '1px solid black',
      fontWeight             : 'bold',
      textAlign              : 'right'
   },
   totalsTable               : {
      width                  : '100%',
      borderSpacing          : '4px'
   },
   totalsLabel               : {
      textAlign              : 'left'
   },
   totalsPound               : {
      width                  : '30px',
      textAlign              : 'right'
   },
   totalsValue               : {
      width                  : '80px',
      textAlign              : 'right',
      fontWeight             : 'bold'
   },
   totalsDelete              : {
      width                  : '30px'
   },
   totalsFinal               : {
      width                  : '80px',
      borderTop              : '1px black solid',
      borderBottom           : '1px solid black',
      textAlign              : 'right',
      fontWeight             : 'bold'
   },
});

//  --------------------------------------------------------------------------------------------------




